import React, { useEffect, useState } from "react";
import "./CreatePDF.css";
import logoPreta from "../../img/logos/logo-btk.png";
import footer from "../../img/footer.png";

export default function CreatePDF() {
  const [proposalData, setProposalData] = useState(null);
  const [isReadyToPrint, setIsReadyToPrint] = useState(false);

  useEffect(() => {
    const storedData = sessionStorage.getItem("proposalData");
    console.log(storedData)
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        console.log(parsedData);
        setProposalData(parsedData);
      } catch (error) {
        console.error("Erro ao parsear os dados da proposta:", error);
      }
    } else {
      console.error("Nenhum dado encontrado para a proposta.");
    }
  }, []);

  useEffect(() => {
    if (proposalData) {
      // 2 seconds delay
      const timeout = setTimeout(() => setIsReadyToPrint(true), 2000);
      return () => clearTimeout(timeout);
    }
  }, [proposalData]);

  useEffect(() => {
    if (isReadyToPrint) {
      const originalTitle = document.title;

      let filename = `proposta_${proposalData?.proposalNumber?.trim()}`;
      document.title = filename;

      window.print();

      setTimeout(() => {
        document.title = originalTitle;
      }, 1000);
    }
  }, [isReadyToPrint, proposalData?.proposalNumber]);

  if (!proposalData || !proposalData.items) {
    console.log("Dados ainda não carregados", proposalData);
    return;
  }

  const formatData = (items) => {
    return items.map((item) => ({
      ...item,
      quantity: item.quantity.replace(/,00$/, ""),
      ncm: item.ncm.replace(/\./g, ""),
    }));
  };

  const formattedItems = formatData(proposalData.items);
  const totalValue = parseFloat(
    proposalData.totalValue.replace(/\./g, "").replace(",", ".")
  );

  if (isNaN(totalValue)) {
    console.error("totalValue não é um número válido", proposalData.totalValue);
    return <div>Valor total inválido.</div>;
  }

  const totalValueLength = proposalData.totalValue.length;

  const secondLineWidth = (() => {
    switch (totalValueLength) {
      case 10:
        return "13rem";
      case 9:
        return "12.5rem";
      case 8:
        return "12.1rem";
      case 6:
        return "11.4rem";
      default:
        return "11rem";
    }
  })();

  const formattedFreightValue =
    proposalData.freightValue === "" ? "0,00" : proposalData.freightValue;

  return (
    <div className="A4" id="pdfContent">
      <header>
        <img src={logoPreta} className="logoBtk" alt="Logo BTK" />
        <div className="btkInfo">
          <h1>BTK SOLUTIONS</h1>
          <p>
            BTK SOLUTIONS DIST. DE EQUIP. E INTEGRACAO DE SISTEMAS ELETRONICOS
            LTDA
            <br />
            CNPJ 32.421.603/0001-53 - I.E. 90890745-06
            <br />
            contato@btk.solutions - (41) 3385-7134
            <br />
            CEP 83.050-160 - Rua Professora Marieta de Souza
            <br />
            Parque da Fonte - São José Dos Pinhais - PR
          </p>
        </div>

        <div className="proposalNumber">
          <h1>PROPOSTA COMERCIAL</h1>
          <div className="number">
            <span>Nº {proposalData.proposalNumber}</span>
            <div className="date">
              <span>{proposalData.proposalDate}</span>
            </div>
          </div>
        </div>
      </header>

      <div className="client">
        <h1>Cliente</h1>
        <p>{proposalData.razaoSocial}</p>
        <p>{proposalData.cnpj}</p>
        <p className="upperCase">
          CEP {proposalData.cep} - {proposalData.city} - {proposalData.state}
        </p>
      </div>

      <div className="generalData">
        <h1>Dados gerais</h1>
        <p>Condição de pagamento: À definir</p>
        <p>Prazo de entrega: {sessionStorage.getItem("deliveryTime")}</p>
        <p>Frete: FOB</p>
      </div>

      <div className="secondGeneralData">
        <p>Validade do orçamento: 15 dias</p>
        <p>Transportadora: À definir</p>
        <p>Vendedor: {proposalData.seller}</p>
      </div>

      <div className="line">
        <hr />
      </div>

      <div className="bottom">
        <div className="productWord">
          <h1>Produtos</h1>
        </div>

        <div className="productsHeader">
          <div className="headerHash">
            <strong>#</strong>
          </div>
          <div className="headerSku">
            <strong>SKU</strong>
          </div>
          <div className="headerDescription">
            <strong>Descrição</strong>
          </div>
          <div className="headerQuantity">
            <strong>Quantidade</strong>
          </div>
          <div className="headerValue">
            <strong>Valor</strong>
          </div>
          <div className="headerSubtotal">
            <strong>Subtotal</strong>
          </div>
          <div className="headerNcm">
            <strong>NCM</strong>
          </div>
        </div>

        {formattedItems.map((item, index) => (
          <React.Fragment key={index}>
            <div className="lineProducts">
              <hr />
            </div>
            <div className="productPDF">
              <div className="productIndex">
                <strong>{index + 1}</strong>
              </div>
              <div className="productSkuPDF">{item.sku}</div>
              <div className="productDescricaoPDF">{item.item}</div>
              <div className="pcPDF">
                <p>PC</p>
              </div>
              <div className="productQuantidadePDF">{item.quantity}</div>
              <div className="reais">
                <p>R$</p>
              </div>
              <div className="productValorPDF">{item.unitPrice}</div>
              <div className="reais">
                <p>R$</p>
              </div>
              <div className="productSubtotalPDF">{item.totalPrice}</div>
              <div className="productNcmPDF">{item.ncm}</div>
            </div>
          </React.Fragment>
        ))}

        <div className="lineDownBelow">
          <hr />
        </div>

        <div className="headerComments">
          <h2>Observações</h2>
        </div>

        <div className="comments">
          <h3 dangerouslySetInnerHTML={{ __html: proposalData.comments }}></h3>
        </div>

        <div
          className={
            !proposalData.discountValue ||
            proposalData.discountValue.trim() === ""
              ? "commentsLineWithoutDiscount"
              : "commentsLine"
          }
        >
          <hr />
        </div>

        <table className="priceDetails">
          <thead>
            <tr>
              <td className="totalsTable">Totais</td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr className="allProducts">
              <td>Total dos produtos</td>
              <td>R$</td>
              <td className="value">{proposalData.totalProducts}</td>
            </tr>
            <tr className="taxes">
              <td>Impostos</td>
              <td>R$</td>
              <td className="value">0,00</td>
            </tr>
            {proposalData.discountValue &&
              proposalData.discountValue.trim() !== "" && (
                <tr className="discount">
                  <td>Desconto</td>
                  <td>R$</td>
                  <td className="value">{proposalData.discountValue}</td>
                </tr>
              )}
            <tr className="freight">
              <td>Frete</td>
              <td>R$</td>
              <td className="value">{formattedFreightValue}</td>
            </tr>
            <tr className="totalValue">
              <td>Valor total</td>
              <td>R$</td>
              <td className="value">{proposalData.totalValue}</td>
            </tr>
          </tbody>
        </table>
        <div className="secondLine">
          <hr style={{ width: secondLineWidth }} />
        </div>
        <div className="footer">
          <p>
            Os termos e condições abaixo, assim como quaisquer modificações
            posteriormente realizadas, integrarão o contrato de compra e venda a
            ser celebrado entre BTK SOLUTIONS e o cliente acima indicado
            (”Cliente”), no que diz respeito aos produtos descritos acima e as
            obrigações de cada parte. Somente após a oferta emitida pela BTK
            SOLUTIONS ter sido aceita pelo Cliente passará a existir contrato de
            compra e venda, que será interpretado como um acordo final entre as
            partes e substitui qualquer outro acordo ou contato prévio oral ou
            escrito.
            <br />
            <br />
            Condições Gerais:
            <br />
            1) Validade da Proposta: Conforme descrito no cabeçalho da cotação.
            <br />
            2) Forma de Pagamento: Conforme descrito no cabeçalho da cotação, em
            dias corridos da data do faturamento, a critério exclusivo da BTK
            SOLUTIONS (sujeito à análise e aprovação de crédito do Cliente).
            <br />
            3) Frete: Conforme descrito no cabeçalho da cotação.
            <br />
            4) Prazo de Entrega: A previsão informada nesta proposta deverá ser
            confirmada quando a proposta for efetivamente aceita pelo Cliente.
            <br />
            5) Transferência da propriedade e do risco: A propriedade e o risco
            sobre os produtos e/ou equipamentos, inclusive no que diz respeito a
            danos, é transferida ao Cliente quando da entrega ao primeiro
            transportador.
            <br />
            6) Garantia dos Produtos/Equipamentos: O prazo de garantia é de 12
            meses.
            <br />
            7) Os valores acima são válidos para solução completa e/ou pacotes
            como apresentados; não são válidos para vendas separadas de um ou
            mais itens.
            <br />
            8) Os valores de venda incluem todos os impostos incidentes sobre a
            operação de responsabilidade da BTK SOLUTIONS, sendo que todos os
            destaques serão feitos no termo da legislação em vigor.
          </p>
          <img
            src={footer}
            alt="BTK Solutions Footer"
            className="footerImage"
          />
        </div>
      </div>
    </div>
  );
}
