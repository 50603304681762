import axios from "axios";
import * as cheerio from "cheerio";

export const ExtractData = async (link) => {
  if (!link) {
    console.error("Link não fornecido");
    return;
  }

  try {
    // Encode the link to make it safe for inclusion in a URL
    const encodedLink = encodeURIComponent(link);

    // Use AllOrigins proxy (only for a short moment)
    const corsProxy = `https://api.allorigins.win/get?url=${encodedLink}&timestamp=${Date.now()}`;

    const response = await axios.get(corsProxy);

    // The actual content is in response.data.contents
    const data = response.data.contents; 
    console.log(data)

    const $ = cheerio.load(data);
    console.log($.html());

    const items = [];
    let index = 1;

    // Capture the proposal number
    const proposalText = $("h2").text();
    const proposalMatch = proposalText.match(/Proposta Comercial Nº (.+)/);
    const proposalNumberValue = proposalMatch ? proposalMatch[1] : "Not found";
    console.log("Proposta Número:", proposalNumberValue);

    // Capture the seller
    const seller = $('p:contains("Vendedor(a):")')
      .text()
      .replace("Vendedor(a):", "")
      .trim();
    console.log("Vendedor:", seller);

    // Capture the proposal date
    let proposalDate = "";
    $("td").each((i, cell) => {
      const cellText = $(cell).text().trim();
      const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
      if (datePattern.test(cellText)) {
        proposalDate = cellText;
        return false;
      }
    });
    console.log("Data da Proposta:", proposalDate);

    // Capture the company name
    let razaoSocial = "Not found";
    $("p").each((i, elem) => {
      const text = $(elem).text().trim();
      if (text.startsWith("Para")) {
        const parts = text.split("Para");
        razaoSocial = parts[1]?.trim() || "Not found";
        return false;
      }
    });
    console.log("Razão Social:", razaoSocial);

    // Capture CNPJ and CEP
    let cnpj = "Not found";
    let cep = "Not found";

    $("td").each((i, elem) => {
      const text = $(elem).text();

      if (text.includes("Endereço do Cliente")) {
        const content = $(elem).text();

        // Regex to find CNPJ and ZIP code
        const cnpjMatch = content.match(/\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/);
        const cepMatch = content.match(/\d{2}\.\d{3}-\d{3}/);

        if (cnpjMatch) cnpj = cnpjMatch[0];
        if (cepMatch) cep = cepMatch[0];

        if (cnpj !== "Not found" && cep !== "Not found") {
          return false; // Stop the loop
        }
      }
    });
    console.log("CNPJ encontrado:", cnpj);
    console.log("CEP encontrado:", cep);
    let city = "Not found";
    let state = "Not found";

    if (cep !== "Not found") {
      try {
        const formattedCep = cep.replace(/\D/g, ""); // Removing non-numeric characters.

        // Function to search for address data based on CEP
        const fetchAddress = async (url) => {
          const response = await axios.get(url);
          if (response.data && response.data.localidade && response.data.uf) {
            return {
              city: response.data.localidade,
              state: response.data.uf,
            };
          }
          return null;
        };

        // First try with ViaCEP
        const viaCepUrl = `https://viacep.com.br/ws/${formattedCep}/json/`;
        let address = await fetchAddress(viaCepUrl);

        if (!address) {
          console.warn(
            "ViaCep não encontrou o endereço. Tentando com OpenCep..."
          );

          // Second try with ViaCEP OpenCep
          const openCepUrl = `https://opencep.com/v1/${formattedCep}.json`;
          address = await fetchAddress(openCepUrl);
        }

        if (address) {
          city = address.city;
          state = address.state;
          console.log("Cidade:", city);
          console.log("Estado:", state);
        } else {
          console.error(
            "Não foi possível encontrar o endereço em nenhum serviço."
          );
        }
      } catch (error) {
        console.error("Erro ao buscar o endereço do CEP:", error);
      }
    }

    const comments = $(".divBorda p").html() || "";
    console.log("Comentários:", comments);

    // Discount extraction
    let discountValue = "";
    const discountRow = $("table.grid th:contains('Desconto')")
      .closest("tr")
      .next();

    if (discountRow.length > 0) {
      discountValue = discountRow.find("td").eq(2).text().trim();
    }
    console.log("Desconto:", discountValue);

    // Freight value without discount extraction
    const freightRow = $("table.grid th:contains('Frete')")
      .closest("tr")
      .next();

    let freightValue = "";
    if (freightRow.length > 0) {
      const freightIndex = discountValue !== "" ? 3 : 2;
      freightValue = freightRow.find("td").eq(freightIndex).text().trim();
    }

    console.log("Frete:", freightValue);

    // Extraction of total value
    const totalValue = $("th:contains('Total da proposta')")
      .closest("tr")
      .next()
      .find("td")
      .last()
      .text()
      .trim();
    console.log("Total:", totalValue);

    // Extraction of proposal items
    $("table.grid tr").each((i, row) => {
      const cells = $(row).find("td");

      if (cells.length > 0) {
        const number = parseInt($(cells[0]).text().trim(), 10);

        if (number !== index) {
          console.log("Índice fora de ordem. Interrompendo a leitura.");
          return false;
        }

        const itemDescription = $(cells[1]).text().trim();
        let sku = $(cells[2]).text().trim();
        const ncm = $(cells[2]).find("small.text-muted").text().trim();
        sku = sku.replace(ncm, "").trim();

        items.push({
          number: number,
          item: itemDescription,
          sku: sku,
          ncm: ncm,
          quantity: $(cells[3]).text().trim(),
          unitPrice: $(cells[5]).text().trim(),
          totalPrice: $(cells[6]).text().trim(),
        });

        index++;
      }
    });

    let totalProducts = "Not found";

    // Capture the value of "Total dos itens"
    $("tr").each((i, row) => {
      const cells = $(row).find("td");
      cells.each((j, cell) => {
        const cellText = $(cell).text().trim();
        if (cellText.includes("Total dos itens")) {
          totalProducts = $(cells[j + 1])
            .text()
            .trim();
          return false;
        }
      });
      if (totalProducts !== "Not found") return false;
    });

    console.log("Total dos itens:", totalProducts);

    // Store all extracted data
    const proposalData = {
      proposalNumber: proposalNumberValue,
      seller,
      proposalDate,
      razaoSocial,
      cnpj,
      cep,
      city,
      state,
      comments,
      freightValue,
      discountValue,
      totalValue,
      totalProducts,
      items,
    };

    console.log("Dados da proposta" + proposalData);
    // Store in sessionStorage
    sessionStorage.setItem("proposalData", JSON.stringify(proposalData));

    console.log("Dados da Proposta:", proposalData);
  } catch (error) {
    console.error("Erro ao buscar as informações:", error);
  }
};
