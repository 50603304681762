import React, { useState } from "react";
import "./ProposalForm.css";
import Header from "../../Shared/Header/Header.js";

export default function ProposalForm() {
  const [link, setLink] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleDeliveryTimeChange = (e) => {
    const value = e.target.value;
    setDeliveryTime(value);
    sessionStorage.setItem("deliveryTime", value);
  };

  const resetFields = () => {
    setLink("");
    setDeliveryTime("");
    sessionStorage.removeItem("proposalLink");
    sessionStorage.removeItem("deliveryTime");
  };

  const handleSubmit = () => {
    if (link && deliveryTime) {
      sessionStorage.setItem("proposalLink", link);
      console.log("Link e prazo de entrega armazenados:", link, deliveryTime);

      window.open("/Loading", "_blank");
      resetFields();
    } else {
      console.error(
        "Por favor, insira um link e selecione um prazo de entrega."
      );
    }
  };

  return (
    <div className="App">
      <Header />
      <main className="app">
        <h1>Link para gerar a proposta</h1>
        <input
          type="text"
          placeholder="Insira aqui o link do Tiny"
          value={link}
          onChange={handleLinkChange}
        />
        <select
          value={deliveryTime}
          onChange={handleDeliveryTimeChange}
          placeholder="Insira aqui o prazo de entrega"
        >
          <option value="" disabled>
            Selecione o prazo de entrega
          </option>
          <option value="Imediato">Imediato</option>
          <option value="7 dias úteis">7 dias úteis</option>
          <option value="15 dias úteis">15 dias úteis</option>
          <option value="30 dias úteis">30 dias úteis</option>
          <option value="45 dias úteis">45 dias úteis</option>
          <option value="60 dias úteis">60 dias úteis</option>
        </select>
        <button onClick={handleSubmit} disabled={!link || !deliveryTime}>
          Gerar PDF
        </button>
      </main>
    </div>
  );
}
