import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProposalForm from "./Pages/ProposalForm/ProposalForm.js";
import Loading from "./Pages/Loading/Loading.js";
import CreatePDF from "./Pages/CreatePDF/CreatePDF.js";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProposalForm />} />
        <Route path="Loading" element={<Loading />} />
        <Route path="/Pdf-BTK-Solutions" element={<CreatePDF />} />
      </Routes>
    </Router>
  );
}

export default App;
