import logoBranca from "../../img/logos/logo-btk-white.svg"
import "./Header.css";

export default function Header() {
  return (
    <header className="header">
      <div className="navbar">
        <img src={logoBranca} className="Logo" alt="BTK SOLUTIONS" />
      </div>
    </header>
  );
}
